import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ProgressBar } from "primereact/progressbar"

import { capitalizedFirst } from "src/utils/capitalized-first-character"
import EmptyPage from "../empty-page"
import { getMegaLiveUser } from "src/services/mega-live-service"
import { MegaLiveType } from "src/components/modal/mega-live-modal/types"
import MegaLiveTable from "src/components/modal/mega-live-modal/mega-live-table"

export default function HomePage() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [isNotHaveData, setIsNotHaveData] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [megaLives, setMegaLives] = useState<MegaLiveType[]>([])

  const getMegaLive = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getMegaLiveUser().then((res: any) => {
      if(res?.data?.data?.length) {
        setMegaLives(res.data.data)
      } else {
        setIsNotHaveData(true)
        setErrorMessage(capitalizedFirst(t("global.no-data-found")))
      }
    }).catch(() => {
      setIsNotHaveData(true)
      setErrorMessage(capitalizedFirst(t("global.no-data-found")))
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getMegaLive()
  }, [])

  if(isNotHaveData && errorMessage && !loading) return (
    <div className="flex h-screen px-3 text-center items-center justify-center">
      <EmptyPage message={errorMessage}/>
    </div>
  )

  return loading ? (
    <div className="flex h-[540px] items-center justify-center">
      <ProgressBar
        mode="indeterminate"
        style={{ height: "5px", width: "50%", maxWidth: "300px" }}
      ></ProgressBar>
    </div>
  ) : (
    <div className="mt-[24px] md:mt-[96px] flex h-full w-full flex-col items-center justify-center rounded-3 bg-white py-2">
      <div className="flex w-full max-w-[1200px] flex-col items-center gap-2 px-[12px]">
        <span className="mt-[20px] text-[16px] font-medium leading-[24px]">
          {t("home-page.module.mega-live")}
        </span>
        <div className="w-full flex flex-col gap-2 border border-gray-300 max-w-[900px] max-h-[500px] overflow-y-scroll no-scrollbar">
          <MegaLiveTable megaLives={megaLives} />
        </div>
      </div>
    </div>
  )
}
